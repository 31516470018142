import React, { useState, useEffect } from 'react'
import { backendDomain } from '../constants';
/** Modal.css is breaking the entire popup. Need to fix it. 
 * import './css/Modal.css';
 */

/**
 * Modal Component
 * 
 * This component displays a modal dialog for a wordle game. It shows different messages 
 * based on whether the user has won or lost the game. The user can submit a message 
 * related to their game experience, which is sent to the backend.
 * 
 * Props:
 * - isCorrect: Boolean indicating if the user has won the game.
 * - solution: String containing the correct word for the game.
 * - turn: Number of guesses taken by the user.
 * - onClose: Function to close the modal.
 * - id: Unique identifier for the game session.
 * - message: String containing any existing message from the user.
 */
export default function Modal({ isCorrect, solution, turn, onClose, id, message }) {
  const [text, setText] = useState("")
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const now = new Date();
    const nextMidnight = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1, 0, 0, 0));
    const timeDifference = nextMidnight - now; // Time until next midnight in milliseconds
    return Math.floor(timeDifference / 1000); // Convert to seconds
  }

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timeLeft]);

  const handleSubmit = async () => {
    const response = await fetch(`${backendDomain}/wordle/update-wordles-game-for-existing-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id, wordle: { message: text } }),
    });
    onClose();
  }

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours}h ${minutes}m ${secs}s`;
  };

  return (
    <div className="modal">
      {isCorrect && (
        <div style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <h1 style={{ marginBottom: '0px', padding: '10px' }}>Well Done!</h1>
          <p className="solution" style={{ color: 'green', fontSize: '24px', fontWeight: 'bold', margin: '10px' }}>🎉 {solution} 🎉</p>
          <p>You solved it in {turn} guesses</p>
          {message === "" && id && (
            <div>
              <textarea 
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Enter a message for your feed...."
                style={{
                  width: '100%',  // Adjust width to 100% of the parent container
                  height: '100px', // Set the height to 100px
                  padding: '10px', // Add padding inside the text area
                  fontSize: '16px', // Set the font size
                  borderRadius: '5px', // Add rounded corners
                  border: '1px solid #ccc', // Border styling
                  boxSizing: 'border-box', // Ensure padding and border are included in the element's width and height
                  fontFamily: 'Helvetica Neue'
                }}
              />
              <br />
              <button className="modal-button" style={{ marginTop: '5px' }} onClick={handleSubmit}>Post on Feed</button>
            </div>
          )}
          {id && (
            <>
              <p>Next Wordle available in:</p>
              <p style={{ color: 'lightcoral', fontSize: '24px' }}>{formatTime(timeLeft)}</p>
            </>
          )}
          <button className="modal-button" onClick={onClose}>Close</button>
        </div>
      )}
      {!isCorrect && (
        <div style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <h1 style={{ marginBottom: '0px', padding: '10px' }}>Oh No! The word was:</h1>
          <p className="solution" style={{ color: 'red', fontSize: '24px', fontWeight: 'bold', margin: '10px' }}>🥺 {solution} 😭</p>
          <p>Better luck tomorrow ♥️</p><br />
          {message === "" && id && (
            <div>
              <textarea
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Enter a message for your feed...."
                style={{
                  width: '100%',  // Adjust width to 100% of the parent container
                  height: '100px', // Set the height to 100px
                  padding: '10px', // Add padding inside the text area
                  fontSize: '14px', // Set the font size to match isCorrect view
                  borderRadius: '5px', // Add rounded corners
                  border: '1px solid #ccc', // Border styling
                  boxSizing: 'border-box', // Ensure padding and border are included in the element's width and height
                  fontFamily: 'Helvetica Neue'
                }}
              />
              <br />
              <button className="modal-button" style={{ marginTop: '5px' }} onClick={handleSubmit}>Post on Feed</button>
            </div>
          )}
          {id && (
            <>
              <p>Next Wordle available in:</p>
              <p style={{ color: 'lightcoral', fontSize: '24px' }}>{formatTime(timeLeft)}</p>
            </>
          )}
          <button className="modal-button" onClick={onClose}>Close</button>
        </div>
      )}
    </div>
  )
}
