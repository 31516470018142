import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { battleService } from '../hooks/battleService';
import Header from './Header';
import Battle from './Battle';
import '../pages/css/WordlePage.css'; // Use WordlePage styling

const BattleGame = () => {
    const { battleId } = useParams();
    const navigate = useNavigate();
    const [battleState, setBattleState] = useState({
        status: 'loading',
        guesses: [],
        solution: '',
    });
    const [error, setError] = useState(null);
    const user = useSelector((state) => state.user);

    useEffect(() => {
        fetchBattleState();
    }, [battleId]);

    const fetchBattleState = async () => {
        try {
            const data = await battleService.getBattleState(battleId);
            setBattleState(data);
        } catch (error) {
            console.error('Error fetching battle state:', error);
            setError('Failed to load battle. Please try again.');
        }
    };

    const handleGuessSubmitted = async (guess) => {
        try {
            const updatedState = await battleService.submitGuess(battleId, guess);
            setBattleState(updatedState);
        } catch (error) {
            console.error('Error submitting guess:', error);
            setError('Failed to submit guess. Please try again.');
        }
    };

    if (error) {
        return (
            <div className="wordle-page">
                <Header />
                <div className="error-container">
                    <h2>Error</h2>
                    <p>{error}</p>
                    <button onClick={() => navigate('/')}>Return to Feed</button>
                </div>
            </div>
        );
    }

    if (battleState.status === 'loading') {
        return (
            <div className="wordle-page">
                <Header />
                <div className="loading-container">Loading battle...</div>
            </div>
        );
    }

    return (
        <div className="wordle-page">
            <Header />
            <div className="game-container">
                {battleState.solution && (
                    <Battle
                        solution={battleState.solution}
                        existingGuesses={battleState.guesses}
                        gameId={battleId}
                        user={user}
                        onGuessSubmitted={handleGuessSubmitted}
                        battleDayWordleSolution={battleState.dailyWordleSolution}
                    />
                )}
                {battleState.status === 'completed' && (
                    <div className="battle-result">
                        <h3>Battle Completed!</h3>
                        <p>Winner: {battleState.winner}</p>
                        <button onClick={() => navigate('/')}>Return to Feed</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BattleGame;