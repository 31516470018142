import React, { useState, useEffect } from "react";
import PostOverview from "./PostOverview";
import './css/FeedComponent.css';
import { backendDomain } from "../constants";
import { isSameDay, parseISO } from 'date-fns'; // Add this import

// Main component for displaying a feed of wordles
const FeedComponent = ({ user, isGlobal = true, isProfilePage = false }) => {
    // State variables
    const [wordles, setWordles] = useState([]); // Array to store wordles
    const [alreadyLoaded, setAlreadyLoaded] = useState(0); // Counter for loaded wordles
    const [loading, setLoading] = useState(false); // Loading state
    const [error, setError] = useState(null); // Error state
    const [hasMorePages, setHasMorePages] = useState(true); // Pagination control
    const [users, setUsers] = useState({}); // Object to store users by email

    // Load wordles on component mount
    useEffect(() => {
        loadWordles();
    }, []);

    // Fetch user data when wordles are loaded
    useEffect(() => {
        if (wordles.length > 0) {
            fetchUsers();
        }
    }, [wordles]);

    // Handle infinite scrolling to load more wordles
    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 50) { // Adjust threshold as needed
                if (!loading && hasMorePages) {
                    loadWordles();
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [loading, hasMorePages]);

    // Function to load wordles from the backend
    const loadWordles = async () => {
        try {
            setLoading(true);
            setError(null);

            // Determine API URL based on global or user-specific loading
            const apiUrl = isGlobal
                ? `${backendDomain}/wordle/load-wordles`
                : `${backendDomain}/wordle/load-wordles-for-user`;
            const requestBody = isGlobal
                ? { alreadyLoaded }
                : { emailId: user.emailId, alreadyLoaded };

            const response = await fetch(apiUrl, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(requestBody),
            });
            setAlreadyLoaded(prev => prev + 1);
            const data = await response.json();
            if (data.status === 200 && data.wordles && Array.isArray(data.wordles)) {
                setWordles(prevWordles => [...prevWordles, ...data.wordles]);

                // Check if the response contains fewer items than the page size (assumed to be 10)
                if (data.wordles.length < 10) {
                    setHasMorePages(false);
                }
            } else {
                setError("Failed to load wordles.");
            }
        } catch (err) {
            setError("An error occurred while fetching wordles.");
        } finally {
            setLoading(false);
        }
    };

    // Function to fetch user data based on wordles
    const fetchUsers = async () => {
        const emails = wordles.map(wordle => wordle.email);
        const uniqueEmails = [...new Set(emails)]; // Remove duplicates

        try {
            const usersData = await Promise.all(uniqueEmails.map(async (email) => {
                const response = await fetch(`${backendDomain}/user/fetch-user-by-email`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ email }),
                });
                const data = await response.json();
                if (data.status === 200) {
                    return { [email]: data.user };
                } else {
                    console.error(`Failed to fetch user data for email: ${email}`);
                    return null;
                }
            }));

            // Update state with users data
            const usersObject = usersData.reduce((acc, user) => ({ ...acc, ...user }), {});
            setUsers(prevUsers => ({ ...prevUsers, ...usersObject }));

        } catch (err) {
            setError("An error occurred while fetching user data.");
        }
    };

    // Function to determine if a post is the first of the day
    const isFirstPostOfDay = (currentPost, previousPost) => {
        if (!previousPost) return true;
        const currentDate = parseISO(currentPost.createdAt);
        const previousDate = parseISO(previousPost.createdAt);
        return !isSameDay(currentDate, previousDate);
    };

    // Render the component
    return (
        <div className={`feed-container ${isProfilePage ? 'profile-page' : ''}`}>
            {wordles.length === 0 && !loading && !error && (
                <p>No Posts Here</p>
            )}
            {error && <p>{error}</p>}
            {wordles.map((wordle, index) => {
                const userForWordle = users[wordle.email];
                const isFirstOfDay = isFirstPostOfDay(wordle, wordles[index - 1]);
                return (
                    <React.Suspense fallback={<p>Loading post...</p>} key={index}>
                        {userForWordle ? (
                            <PostOverview
                                user={userForWordle}
                                wordle={wordle}
                                isFirstOfDay={isFirstOfDay}
                                isProfilePage={isProfilePage}
                            />
                        ) : (
                            <p>Loading user data...</p>
                        )}
                    </React.Suspense>
                );
            })}
            {loading && <p>Loading...</p>}
            {!hasMorePages && !loading && <p>That's it!</p>}
        </div>
    );
};

export default FeedComponent;