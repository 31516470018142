import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import FeedPage from './pages/FeedPage';
import BattleGame from './components/BattleGame';
import Wordle from './pages/WordlePage';
import Feed from './pages/FeedPage';
import Profile from './pages/ProfilePage';
import ProfilePageExternalView from './pages/ProfilePageExternalView';
import ScrollToTop from './components/ScrollToTop';
import PostDetail from './components/PostDetail';
import BackAppBar from './components/BackAppBar';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <LocationHandler />
      <Routes>
        <Route path="/" element={<FeedPage />} />
        <Route path="/wordle" exact element={<Wordle />} />
        <Route path="/profile" exact element={<Profile />} />
        <Route path="/profile/:id" exact element={<ProfilePageExternalView />} />
        <Route path="/post/:postId" element={<PostDetail />} />
        <Route path="/battle/:battleId" element={<BattleGame />} />
      </Routes>
    </Router>
  );
}

function LocationHandler() {
  const location = useLocation();
  const isPostDetailPage = location.pathname.startsWith('/post/');
  const isBattlePage = location.pathname.startsWith('/battle/');

  return (isPostDetailPage) ? <BackAppBar /> : null;
}

export default App;
