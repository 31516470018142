import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveUser } from '../reducers/user';
import Header from '../components/Header';
import Wordle from '../components/Wordle';
import WordleOverlayComponent from '../components/WordleOverlayComponent';
import { backendDomain } from '../constants';

const WordlePage = () => {
    const [solution, setSolution] = useState(null);
    const [message, setMessage] = useState("");
    const [existingGuesses, setExistingGuesses] = useState([]);
    const [gameId, setGameId] = useState(null); // Store the Wordle game ID
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser) {
            dispatch(saveUser(storedUser));
        } else {
            dispatch(saveUser(null));
        }
    }, [dispatch]);

    useEffect(() => {

        if (user.status === 'loading') return; // Skip if still loading user data or in idle state
        if (user.status === 'failed') {
            console.error('Error loading user:', user.error);
            return; // Handle errors as needed
        }

        if (user.emailId) {
            console.log('Fetching Wordle for logged-in user');
            async function fetchWordle() {
                console.log("Fetch wordle called for existing user")
                await fetch(`${backendDomain}/wordle/generate-wordle`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email: user.emailId, userId: user._id }),
                })
                    .then(res => res.json())
                    .then(json => {
                        const { solution, guesses, _id, message } = json.wordle;
                        setSolution(solution);
                        setExistingGuesses(guesses || []);
                        setGameId(_id); // Store the game ID
                        setMessage(message);
                    })
                    .catch(err => console.error('Error fetching Wordle:', err));
            }
            fetchWordle()
        } else {
            console.log('User not logged in. No wordle');
        }
    }, [user.emailId]); // Ensure this effect runs when user.emailId or user.status changes

    const showOverlay = !user.emailId || (user.emailId && existingGuesses && existingGuesses.length === 0);

    return (
        <>
            <Header />
            {showOverlay && <WordleOverlayComponent user={user} />}
            {solution && (
                <Wordle
                    solution={solution}
                    existingGuesses={existingGuesses}
                    gameId={gameId} // Pass the game ID to the Wordle component
                    user={user}
                    message={message} // Pass the user's email to the Wordle component
                />
            )}
        </>
    );
};

export default WordlePage;
