/**
 * Custom hook for managing the Wordle game state.
 * 
 * @param {string} solution - The correct word to guess.
 * @param {Array<string>} existingGuesses - Previous guesses made by the user.
 * @param {string} gameId - Unique identifier for the game.
 * @param {Object} user - User information, including emailId.
 * @param {string} message - Additional message to send with the game update.
 * @param {Object} initialUsedKeys - Initial used keys state from localStorage.
 * 
 * @returns {Object} - Contains game state and functions to handle user input.
 */

import { useState, useEffect, useCallback } from 'react';
import { backendDomain } from '../constants';
import { battleService } from './battleService';

const useWordle = (solution, existingGuesses = [], gameId, user, message, initialUsedKeys = {}, gameMode = "dailyWordle", battleDayWordleSolution = '') => {
  // Function to format existing guesses for display
  const formatExistingGuess = (guess = '', solution) => {
    // If no guess is provided, return null
    if (!guess) return null;

    // Create a copy of the solution to track which letters have been matched
    let solutionArray = [...solution];

    // Map over each letter in the guess to determine its display color
    return [...guess].map((l, i) => {
      let color = 'grey'; // Default color is grey (not in the solution)

      // Check if the letter is in the correct position (green)
      if (solution[i] === l) {
        color = 'green'; // Set color to green if the letter is correct
        solutionArray[i] = null; // Remove the letter from the solution array
      } 
      // Check if the letter is in the solution but in the wrong position (yellow)
      else if (solutionArray.includes(l)) {
        color = 'yellow'; // Set color to yellow if the letter is in the solution
        solutionArray[solutionArray.indexOf(l)] = null; // Remove the letter from the solution array
      }

      // Return an object containing the letter and its corresponding color
      return { key: l, color };
    });
  };

  const maxTurns = gameMode === 'battle' ? 4 : 6;
  const [turn, setTurn] = useState(existingGuesses.length);
  const [currentGuess, setCurrentGuess] = useState('');
  const [guesses, setGuesses] = useState(() => {
      return [...Array(maxTurns)].map((_, i) => formatExistingGuess(existingGuesses[i], solution));
  });
  const [history, setHistory] = useState(existingGuesses);
  const [isCorrect, setIsCorrect] = useState(existingGuesses[existingGuesses.length - 1] === solution);
  const [usedKeys, setUsedKeys] = useState(initialUsedKeys);

  // Update the guesses, history, and turn based on existingGuesses and solution
  useEffect(() => {
    setGuesses([...Array(maxTurns)].map((_, i) => formatExistingGuess(existingGuesses[i], solution)));
    setHistory(existingGuesses);
    setTurn(existingGuesses.length);
  }, [existingGuesses, solution]);

  useEffect(() => {
    setIsCorrect(existingGuesses.length > 0 ? existingGuesses[existingGuesses.length - 1] === solution : false)
  }, [existingGuesses])

  // Function to format the current guess for display
  const formatGuess = () => {
    try {
        console.log('Formatting guess:', currentGuess);
        console.log('Solution:', solution);
        
        const solutionArray = [...solution];
        const formattedGuess = [...currentGuess].map((l) => ({
            key: l,
            color: 'grey'
        }));

        // First pass: mark green letters
        formattedGuess.forEach((l, i) => {
            if (l.key === solutionArray[i]) {
                formattedGuess[i].color = 'green';
                solutionArray[i] = null;
                console.log(`Green at position ${i}: ${l.key}`);
            }
        });

        // Second pass: mark yellow letters
        formattedGuess.forEach((l, i) => {
            if (l.color === 'grey') {
                const index = solutionArray.indexOf(l.key);
                if (index !== -1) {
                    formattedGuess[i].color = 'yellow';
                    solutionArray[index] = null;
                    console.log(`Yellow at position ${i}: ${l.key}`);
                }
            }
        });

        console.log('Formatted Guess:', formattedGuess);
        return formattedGuess;
    } catch (error) {
        console.error('Error in formatGuess:', error);
        return [];
    }
  };

    // Function to calculate the streak for a user
  const calculateStreak = async (emailId) => {
    try {
      const response = await fetch(`${backendDomain}/wordle/get-user-streak-information`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ emailId }),
      });
      const data = await response.json();
      if (data.status === 200) {
        return [data.currentStreak, data.maxStreak];
      } else {
          console.error('Failed to fetch streak information after completing a game');
      }
    } catch (error) {
      console.error('Error calculating streak:', error);
      return [0, 0];
    }
  };

  // Function to update the max streak
  const updateMaxStreak = async (emailId, maxStreak) => {
    try {
      console.log("Updating max streak:", emailId, maxStreak);
      
      const reqBody = { emailId: emailId, maxStreak: maxStreak }
      const response = await fetch(`${backendDomain}/user/update-max-streak`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(reqBody),    
        
      });
      const data = await response.json();
      if (data.status === 200) {
        return data.maxStreak;
      } else {
        console.error('Failed to update max streak:', data);
      }
    } catch (error) {
      console.error('Error updating max streak:', error);
    }
  };

  // Function to add a new guess to the game state
  const addNewGuess = async (formattedGuess) => {
    
    // Check for game end conditions
    if (currentGuess === solution)
    {
      setIsCorrect(true);
    };
    if ((gameMode === 'battle' && turn >= 4) || (gameMode !== 'battle' && turn >= 6))
    {
      setIsCorrect(false);
    }

    if (gameMode === "battle") {
      console.log("Updating battle details for battleID ", gameId, "with guess ", currentGuess);
      battleService.updateBattleForBattleID(gameId, currentGuess);
    }

    setGuesses((prevGuesses) => {
      let newGuesses = [...prevGuesses];
      newGuesses[turn] = formattedGuess;
      return newGuesses;
    });

    setHistory((prevHistory) => [...prevHistory, currentGuess]);
    setTurn((prevTurn) => prevTurn + 1);


    // Update usedKeys on keyboard. Delay is set in index.css
    setUsedKeys((prevUsedKeys) => {
      const newUsedKeys = { ...prevUsedKeys };
      formattedGuess.forEach((l) => {
        const currentColor = newUsedKeys[l.key];
        if (l.color === 'green') {
          newUsedKeys[l.key] = 'green';
        } else if (l.color === 'yellow' && currentColor !== 'green') {
          newUsedKeys[l.key] = 'yellow';
        } else if (l.color === 'grey' && currentColor !== ('green' || 'yellow')) {
          newUsedKeys[l.key] = 'grey';
        }
      });
      return newUsedKeys;
    });

    setCurrentGuess('');
  };

  const submitGuess = async () => {
    const reqBody = { wordleId: gameId, guess: currentGuess }
    const response = await fetch(`${backendDomain}/wordle/submitGuess`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(reqBody),
        });
        const data = await response.json();
        if (data.status === 403) {
          alert('Invalid word!');
        }
        else if (data.status !== 200) {
          alert('Error submitting guess. Please try again.');
        }
        else {
          console.log("Wordle game updated successfully by server");
        }
    };

  // Handle keyboard input for user guesses
  const handleKeyup = ({ key }) => {
    if (key === 'Enter') {
      if (turn > maxTurns - 1) {
        console.log('you used all your guesses!');
        return;
      }
      if (history.includes(currentGuess)) {
        alert('You already tried that word.');
        return;
      }
      if (currentGuess.length !== 5) {
        alert('Too short.');
        return;
      }

      validateGuess(currentGuess).then(isValid => {
        console.log("Is guess valid:", isValid);
        if (!isValid) {
          alert('Invalid word!');
          return;
        }

        // Only execute if the guess is valid
        submitGuess()
          .then(() => {
            console.log("Wordle game updated successfully by server");
            const formatted = formatGuess();
            addNewGuess(formatted);
          })
          .catch(error => {
            console.error("Error updating Wordle game by server:", error);
          });
      });
    }
    if (key === 'Backspace') {
      setCurrentGuess((prev) => prev.slice(0, -1));
      return;
    }
    if (/^[A-Za-z]$/.test(key)) {
      if (currentGuess.length < 5) {
        setCurrentGuess((prev) => prev + key);
      }
    }
  };

  // Function to update the game state on the server
  const updateWordleGameForUser = async (id, wordle) => {
    try {
      console.log("Updating the game details:", id, wordle);
      const response = await fetch(`${backendDomain}/wordle/update-wordles-game-for-existing-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, wordle }),
      });
      const data = await response.json();
      if (response.status !== 200) {
        throw new Error(`Failed to update Wordle game: ${data.message}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Function to reset usedKeys state and remove from localStorage
  const resetUsedKeys = useCallback(() => {
    setUsedKeys({});
    localStorage.removeItem('usedKeys');
  }, []);

  // Effect to reset usedKeys when a new game starts (gameId changes)
  useEffect(() => {
    if (gameId) {
      const storedGameId = localStorage.getItem('currentGameId');
      if (storedGameId !== gameId) {
        resetUsedKeys();
        localStorage.setItem('currentGameId', gameId);
      }
    }
  }, [gameId, resetUsedKeys]);

  // Effect to update localStorage whenever usedKeys changes
  useEffect(() => {
    localStorage.setItem('usedKeys', JSON.stringify(usedKeys));
  }, [usedKeys]);

  return { turn, currentGuess, guesses, isCorrect, usedKeys, handleKeyup, resetUsedKeys };
};

const validateGuess = async (guess) => {
  try {
    const response = await fetch(`${backendDomain}/wordle/validate-guess`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ guess: guess.toLowerCase() }),
    });
    const data = await response.json();
    console.log("Backend response:", response.status, data);
    if (response.status !== 200) {
      throw new Error(`Failed to validate guess: ${data.message}`);
    }
    return data.validGuess;
  } catch (error) {
    console.error("Error validating guess:", error);
    return false; // Return false on error to prevent undefined behavior
  }
}

export default useWordle;