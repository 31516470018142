import React, { useState, useEffect } from 'react';
import useWordle from '../hooks/useWordle';
import GridBattle from './GridBattle';
import Keypad from './Keypad';
import Modal from './Modal';

/**
 * Wordle component that manages the game UI and state.
 * 
 * @param {Object} props
 * @param {string} props.solution - The correct word to guess.
 * @param {Array<string>} props.existingGuesses - Previous guesses made by the user.
 * @param {string} props.gameId - Unique identifier for the game.
 * @param {Object} props.user - User information.
 * @param {string} props.message - Additional message related to the game.
 */

export default function Battle({ solution, existingGuesses, gameId, user, message, onGuessSubmitted, battleDayWordleSolution }) {
  const [initialGuesses, setInitialGuesses] = useState(existingGuesses);
  // Removed redundant state for gameMode
  console.log("Starting Battle Rendering");

  if(battleDayWordleSolution === undefined){
    battleDayWordleSolution = "     ";
  } 
  // Retrieve initial usedKeys from localStorage
  const initialUsedKeys = (() => {
    const storedUsedKeys = localStorage.getItem('usedKeys');
    return storedUsedKeys ? JSON.parse(storedUsedKeys) : {};
  })();

  // Use the custom useWordle hook to manage game state
  const { currentGuess, guesses, turn, isCorrect, usedKeys, handleKeyup, resetUsedKeys } = useWordle(solution, initialGuesses, gameId, user, message,initialUsedKeys, "battle", battleDayWordleSolution);
  const [showModal, setShowModal] = useState(false);
  const [modalShown, setModalShown] = useState(false);
  const maxTurns = 4;

  // Update initialGuesses when existingGuesses prop changes
  useEffect(() => {
    setInitialGuesses(existingGuesses);
  }, [existingGuesses]);

  // Handle keyboard events and show modal when game ends
  useEffect(() => {
    window.addEventListener('keyup', handleKeyup);
    if (isCorrect || turn > maxTurns - 1)
      window.removeEventListener('keyup', handleKeyup);

    if ((isCorrect || turn > maxTurns - 1) && !modalShown) {
      setModalShown(true);
      setTimeout(() => setShowModal(true), 2000);
    }

    return () => window.removeEventListener('keyup', handleKeyup);
  }, [handleKeyup, isCorrect, turn, modalShown]);

  const handleCloseModal = () => {
    setShowModal(false);
    if (!gameId)
      window.location.reload();
  };


  return (
    <div>
      <GridBattle 
        guesses={guesses} 
        currentGuess={currentGuess} 
        turn={turn} 
        solution={solution} 
        battleDayWordleSolution={battleDayWordleSolution}  // Pass this new prop
      />
      <Keypad usedKeys={usedKeys} handleKeyup={handleKeyup} isCorrect={isCorrect} />
      {showModal && <Modal isCorrect={isCorrect} turn={turn} solution={solution.toUpperCase()} onClose={handleCloseModal} id={gameId} message={message} />}
    </div>
  );
}
