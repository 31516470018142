import React from 'react'
import './css/Grid.css'
// components
import Row from './Row'

// GridBattle component for a different game mode
export default function GridBattle({ guesses, currentGuess, turn, solution, battleDayWordleSolution }) {
  console.log("Guesses in GridBattle:", guesses);

  // Helper function to format the solution row
  const formatSolutionRow = (solution, battleDayWordleSolution) => {
    const battleDayArray = [...battleDayWordleSolution];
    const formattedSolution = solution.split('').map(() => ({ key: ' ', color: 'grey' }));

    // First pass: mark green
    formattedSolution.forEach((obj, index) => {
      if (solution[index] === battleDayArray[index]) {
        obj.color = 'green';
        battleDayArray[index] = null;
      }
    });

    // Second pass: mark yellow
    formattedSolution.forEach((obj, index) => {
      if (obj.color !== 'green' && battleDayArray.includes(solution[index])) {
        obj.color = 'yellow';
        battleDayArray[battleDayArray.indexOf(solution[index])] = null;
      }
    });

    return formattedSolution;
  };

  const formattedSolutionRow = formatSolutionRow(solution, battleDayWordleSolution);

  return (
    <div className='grid-wordle'>
      {/* First row: battleDayWordleSolution */}
      <Row specialRow="daily" guess={battleDayWordleSolution.split('').map(letter => ({ key: letter, color: 'daily' }))} />
      
      {/* Second row: solution compared to battleDayWordleSolution (hidden letters) */}
      <Row specialRow="solution" guess={formattedSolutionRow} />
    
      {/* Remaining 4 rows for user guesses */}
      {guesses.map((g, i) => {
        if (turn === i) {
          return <Row key={i} currentGuess={currentGuess} />
        }
        return <Row key={i} guess={g} />
      })}
    </div>
  )
}
