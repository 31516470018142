import React, { useEffect } from 'react';
import { googleLogout } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { saveUser, clearUser } from '../reducers/user';
import './css/ProfilePage.css'; // Import the CSS for styling
import '../components/css/WordleOverlayComponent.css';
import Header from '../components/Header';
import UserOverview from '../components/UserOverview';
import GuessDistributionOverview from '../components/GuessDistributionOverview';
import FeedComponent from '../components/FeedComponent';
import LogoutIcon from '@mui/icons-material/Logout'; // Import the Logout icon

const ProfilePage = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser) {
            console.log("Already logged in")
            dispatch(saveUser(storedUser));
        }
    }, [dispatch, user.imageUri]);

    const onSuccess = (response) => {
        console.log('Login Successful:', response);
        const decoded = jwtDecode(response.credential);
        console.log('Decoded JWT:', decoded);

        const { given_name, family_name, email, picture } = decoded;

        const userData = {
            firstName: given_name,
            lastName: family_name,
            emailId: email,
            imageUri: picture,
        };

        // Save user data to Redux store and localStorage
        localStorage.setItem('user', JSON.stringify(userData));
        dispatch(saveUser(userData)); // Dispatch the async thunk to save user data
    };

    const onLogout = () => {
        googleLogout();
        dispatch(clearUser());
        localStorage.removeItem('user'); // Clear user data from localStorage
    };

    return (
        <>
            <Header />
            <div className={`profile-page ${user.emailId ? 'profile-page-logged-in' : 'profile-page-logged-out'}`}>
                {user.emailId ? (
                    <div className="profile-content">
                        <UserOverview user={user} />
                        <GuessDistributionOverview user={user} />
                        <FeedComponent user={user} isGlobal={false} isProfilePage={true} />
                    </div>
                ) : (
                    <>
                        <div className="container">
                            <div className="icon">
                                <div className="grid">
                                    {[...Array(9)].map((_, i) => (
                                        <div key={i} className={`grid-cell ${i >= 3 && i <= 5 ? 'green-cell' : ''}`}></div>
                                    ))}
                                </div>
                            </div>
                            <h1 className="title">Wordle Stats</h1>
                            <p className="description">Login and start building your wordle profile</p>
                        </div>
                        <div className="profile-content">
                            <h3>Login to continue</h3>
                            <div className="google-login-container">
                                <GoogleLogin
                                    onSuccess={onSuccess}
                                    onError={() => {
                                        console.log('Login Failed');
                                    }}
                                    useOneTap
                                />
                            </div>
                        </div>
                    </>
                )}
                {user.emailId && (
                    <div className="logout-icon-container" onClick={onLogout}>
                        <LogoutIcon style={{ color: 'grey', marginBottom: '-10px' }} />
                        <p style={{ fontSize: '12px', color: 'grey' }}>Logout</p> {/* Adjusted font size and color */}
                    </div>
                )}
            </div>

        </>
    );
};

export default ProfilePage;
