import { backendDomain } from '../constants';

export const battleService = {
    startBattle: async (postId, userId) => {
        try {
            const response = await fetch(`${backendDomain}/wordle/battle/${postId}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ postId, userId })
            });
            return await response.json();
        } catch (error) {
            console.error('Error starting battle:', error);
            throw error;
        }
    },

    getBattleState: async (battleId) => {
        try {
            const response = await fetch(`${backendDomain}/wordle/battle/${battleId}/status`);
            return await response.json();
        } catch (error) {
            console.error('Error fetching battle state:', error);
            throw error;
        }
    },

    // Function to update the game state on the server
    updateBattleForBattleID : async (battleId, guess) => {
        try {
        console.log("Updating battle details for battleID: ", battleId);
            const response = await fetch(`${backendDomain}/wordle/battle/${battleId}/guess`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({ guess }),
            });
            const data = await response.json();
            if (response.status !== 200) {
                throw new Error(`Failed to update Battle game: ${data.message}`);
            }
        } catch (error) {
            console.error(error);
        }
    },


    submitGuess: async (battleId, guess) => {
        try {
            const response = await fetch(`${backendDomain}/wordle/battle/${battleId}/guess`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ guess }),
            });
            if (!response.ok) {
                throw new Error('Failed to submit guess');
            }
            return await response.json();
        } catch (error) {
            console.error('Error submitting guess:', error);
            throw error;
        }
    },

    getBattleResult: async (battleId) => {
        try {
            const response = await fetch(`${backendDomain}/battle/${battleId}/result`);
            return await response.json();
        } catch (error) {
            console.error('Error fetching battle result:', error);
            throw error;
        }
    }
};